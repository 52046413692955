* { margin: 0; padding: 0; font-family: 'Josefin Sans', sans-serif;
    font-family: 'Poppins', sans-serif; }


    .foot {
        background-color: #4268AE;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(201, 201, 201);
        animation: fadeInUp;
        animation-duration: 1.5s;
        padding: 20px 0 0 0;
        color: white;
    }
    
    .foot2 {
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 8%;

    }
    
    .frow1, .frow2, .frow3 {
        flex: 1;
        margin: 10px;
        min-width: 250px;
        /* border: solid 1px red; */
    }

    .frow2, .frow3 {
        padding-top: 2%;
    }

    /* .connect p{
        border: solid 1px red;
        padding-bottom: -21px;
    } */

    .ftlogo {
        max-width: 100%;
        height: 200px;
        padding-right: 15%;
    }
    
    .footnav {
        display: flex;
        flex-direction: column;
    }
    
    .navtext11 {
        color: white !important;
        font-size: 15px !important;
        margin-bottom: -9px;
    }
    
    .footions {
        line-height: 1.5;
        padding-left: 5%;
    }
    
    .social-icons {
        display: flex;
        gap: 10px;
        justify-content: start;
        margin-bottom: 3%;
    }
    
    .talktousfoot {
        font-size: 15px;
        background-color: white;
        font-weight: 600;
        border-radius: 2px;
        padding: 5px 30px;
        color: #4268AE !important;
        text-decoration: none;
    }
    
    .foot3 {
        background-color: #4268AE;
        border-top: 1px solid rgb(201, 201, 201);
        text-align: center;
        padding: 10px 0 1px 0;
        color: rgb(232, 232, 232);
        font-size: 14px;
    }
    
    @media screen and (max-width: 950px) {
        .foot2 {
            flex-direction: column;
            align-items: center;
        }
    
        .frow1, .frow2, .frow3 {
            min-width: 100%;
            margin: 10px 0;
        }
    
        .ftlogo {
            height: 180px;
        }
    }
    