* { margin: 0; padding: 0; font-family: 'Josefin Sans', sans-serif;font-family: 'Poppins', sans-serif; }

.maincs{
    /* border: 1px solid yellow; */
    padding: 3% 20%;
    display:  flex;
    justify-content: space-between;
  }
  
  .case1{
    border-top: 12px solid #4268AE;
    border-right: 1px solid #4268AE;
    border-left: 1px solid #4268AE;
    border-bottom: 1px solid #4268AE;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 450px;
    padding: 3%;
    border-radius: 12px;
    animation: slideInLeft; /* slideInRight slideInDown slideInUp */
    animation-duration: 1.5s;
  }

  .case2{
    border-top: 12px solid #4268AE;
    border-right: 1px solid #4268AE;
    border-left: 1px solid #4268AE;
    border-bottom: 1px solid #4268AE;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 450px;
    padding: 3%;
    border-radius: 12px;
    animation: slideInRight; /* slideInRight slideInDown slideInUp */
    animation-duration: 1.5s;
  }

  .maincase{
    /* border: 1px solid red; */
    padding: 2% 5%;
    justify-content: center;
    text-align: center;
    background-color: #F8FCFE;
    animation: slideInDown; /* slideInRight slideInDown slideInUp */
    animation-duration: 1.5s;
  }
  .maincase p{
    /* border: 1px solid yellow; */
    padding: 1px 7% 0 7%;
    color: rgba(72, 72, 72, 0.792);
  }
  .maincase h1{
    /* border: 1px solid green; */
    color: #4268AE;
    padding-top: 2%;
    font-size: 25px;
  }

  .case1 h1 {
    color: #4268AE;
    font-size: 24px;
    font-weight: 500;
    padding-top: 2%;
  }
  
  .cs1btn{
    background-color: #4268AE;
    color: white;
    padding: 8px 20px 8px 20px; 
    font-size: 14px;
    font-weight: 600;
    border-style: none;
  }
  
  .case2 h1 {
    color: #4268AE;
    font-size: 24px;
    font-weight: 500;
    padding-top: 2%;
  }
  
  .case2 h2 {
    font-size: 18px;
    color: #5f5f5f;
  }

  .case1 h2 {
    font-size: 18px;
    color: #5f5f5f;
  }
  
  .case2 p {
    font-size: 14px;
    color: #5f5f5f;
    padding-bottom: 1%;
    padding-top: 1%;
  }

  .case1 p {
    font-size: 14px;
    color: #5f5f5f;
    padding-bottom: 1%;
    padding-top: 1%;
  }
  
  .cs1btn{
    background-color: #4268AE;
    color: white;
    padding: 8px 20px 8px 20px; 
    font-size: 14px;
    font-weight: 600;
    border-style: none;
  }


  
  @media screen and (max-width: 1637px){.maincs{padding: 4% 20%;}}
  @media screen and (max-width: 1521px){.maincs{padding: 4% 16%;}}
  @media screen and (max-width: 1378px){.maincs{padding: 4% 13%;}}
  @media screen and (max-width: 1231px){.maincs{padding: 4% 9%;}}
  @media screen and (max-width: 1119px){.maincs{padding: 4% 7%;}}
  @media screen and (max-width: 1068px){.maincs{flex-direction: column; align-items: center;}.case2{margin-top: 3%; width: 90%;}.case1{width: 90%;}}
  @media screen and (max-width: 1068px){.maincase{padding: 4% 5%;}}
  @media screen and (max-width: 700px){.case1{width: 100%;}.case2{width: 100%;}}

  /* ----------------------- more info ---------------------------- */

.md-htre{
  background-color: #F1F1F1;
  font-weight: 400;
  height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.md-htre strong{
  color: #4268AE;
}

.detail{
  color: #4268AE;
}

.detplan{
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
}

/* .detplan a{
  font-size: 13px;
  text-decoration: none;
  background-color: #4268AE;
  padding: 8px 17px;
  color: white;
  border-radius: 5px;
} */

.master-detail-container {
  display: flex;
}

.option-title{
  font-weight: 500;
  font-size: 18px;
  padding-top: 3%;
  padding-bottom: 6px;
  border-bottom: 1px solid #5f5f5f24;
}

.option-label{
  font-size: 17px;
  border-bottom: 1px solid #5f5f5f24;
  color: #444444c9;
}

.option:hover {
  cursor: pointer;
}
  
.master-one  {
  border: 1px solid #ccc;
  background-color: #F1F1F1;
  padding: 6%;
  border-top: 10px solid #4268AE;
  border-radius: 2%;
  
}

.option.selected .option-label {
  color: #3472e4;
}

.enhan{
  color: #4268AE;
  font-weight: 400;
}

.option-arrow{
  font-size: 18px;
  width: 10%;
}

.optiontitle{
  text-align: center;
  font-weight: 700;
  padding: 1px 0;
  font-size: 21px;
  color: #434343;
}

.selected-option-image{
  height: auto;
  max-width: 100%;
  margin-bottom: 2%;
}

.detail-section {
  padding: 1% 2% 2% 2%;
  /* border: 1px solid red; */
  background-color: #F1F1F1;
}
  
.detail-section h2 {
  margin-bottom: 10px;
}
  
.selected-option-details h3 {
  margin-bottom: 10px;
}
  
.selected-option-details p {
  line-height: 1.5;
}


.selected-option-images button {
  bottom: 10px;
  padding: 5px 10px; 
  background-color: #4268AE; 
  color: white;
  border: none; 
  border-radius: 5px; 
  cursor: pointer;
}

.selected-option-images button:hover {
  background-color: #34507E; 
}

.master-section {
  flex: .6;
  padding: 20px;
  margin-top: 30px;
  border-right: 1px solid #ccc;
  width: 10%;
  float: left;
  /* border: 1px solid green; */
}

.detail-section1 {
  flex: 2;
  padding: 1% 2% 2% 2%;
  /* border: 1px solid red; */
  background-color: white;
  width: 80%;
}

.video-size {
  width: 1300px;
  height: 730px;
  /* border: solid 1px red; */
}


/* .optiontitle{
  border: solid 1px red;
} */


@media screen and (max-width: 1230px){.master-section{width: 100%; border-right: none;}.detail-section1{width: 100%;}.master-detail-container{flex-direction: column;}.master-one{margin-top: -5%;}}
 @media screen and (max-width: 1650px){.video-size{width: 1100px; height: 600px;}}
 @media screen and (max-width: 1560px){.video-size{width: 1000px; height: 600px;}}
 @media screen and (max-width: 1436px){.video-size{width: 950px; height: 550px;}}
 @media screen and (max-width: 1436px){.video-size{width: 950px; height: 550px;}}
 @media screen and (max-width: 1366px){.video-size{width: 900px; height: 500px;}}
 @media screen and (max-width: 1300px){.video-size{width: 860px; height: 500px;}}
 @media screen and (max-width: 1230px){.video-size{width: 1100px; height: 600px;}}
 @media screen and (max-width: 1172px){.video-size{width: 1000px; height: 600px;}}
 @media screen and (max-width: 1066px){.video-size{width: 950px; height: 550px;}}
 @media screen and (max-width: 1018px){.video-size{width: 900px; height: 500px;}}
 @media screen and (max-width: 962px){.video-size{width: 850px; height: 470px;}}
 @media screen and (max-width: 906px){.video-size{width: 750px; height: 400px;}}
 @media screen and (max-width: 906px){.video-size{width: 750px; height: 400px;}}
 @media screen and (max-width: 804px){.video-size{width: 700px; height: 350px;}}
 @media screen and (max-width: 744px){.video-size{width: 650px; height: 350px;}}
 @media screen and (max-width: 694px){.video-size{width: 570px; height: 300px;}}
 @media screen and (max-width: 612px){.video-size{width: 550px; height: 300px;}}
 @media screen and (max-width: 588px){.video-size{width: 470px; height: 250px;}}
 @media screen and (max-width: 588px){.md-htre{font-size: 16px;}.detail{display: none;}.optiontitle{font-size: 16px;}}
 @media screen and (max-width: 588px){.video-size{width: 470px; height: 250px;}}
 @media screen and (max-width: 502px){.video-size{width: 450px; height: 250px;}}
 @media screen and (max-width: 502px){.video-size{width: 450px; height: 250px;}}
 @media screen and (max-width: 482px){.video-size{width: 420px; height: 250px;}}
 @media screen and (max-width: 450px){.video-size{width: 400px; height: 220px;}}
 @media screen and (max-width: 428px){.video-size{width: 375px; height: 220px;}}
 @media screen and (max-width: 404px){.video-size{width: 350px; height: 200px;}}
 @media screen and (max-width: 378px){.video-size{width: 320px; height: 175px;}}
 @media screen and (max-width: 344px){.video-size{width: 300px; height: 160px;}}

/* @media screen and (max-width: 768px) {
  .selected-option-image {
    content: url('/public/images/more/18.png');
  }
} */

/* ----------  860 ----------- */
.hamburger-menu {
  display: none; 
}

.menu-icon {
  display: block;
  cursor: pointer;
  padding: 10px;
}

.m3line{
  border: solid 2px #5f5f5f;
  width: 43px;
  padding: 2px 6px;
  border-radius: 5px;
}

.menu-line {
  width: 27px;
  height: 3px;
  background-color: #4268AE;
  margin: 5px 0;
}

.menu-categories {
  display: block;
  padding-top: 1%;
}

.menu-group {
  margin-bottom: 10px;
}

.menu-title {
  font-weight: 600;
  font-size: 20px;
  /* border: 1px solid blue;  */
  color: #4268AE;
}

.menu-option {
  cursor: pointer;
  padding: 3px;
  font-size: 16px;
  /* border: 1px solid blue; */
  color: #5f5f5f;
  font-weight: 400;
}

.menu-option:hover {
  background-color: #f0f0f0;
}

/*  768px */
@media screen and (max-width: 1230px) {
  .master-section {
    display: none; 
  }

  .hamburger-menu {
    display: block; 
    margin-bottom: 12px;
    /* border: 1px solid red; */
    padding: 1% 3% 0 2%;
  }

  .detail-section1 {
    width: 100%; 
  }
}


 /* ----------------res -------- */

 .wow {
  background-color: #F8FCFE;
  text-align: center;
  justify-content: center;
  padding: 3% 2%;
  display: flex;
  flex-wrap: wrap;
}

 .wowsec{
  background-color: #F8FCFE;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 4% 0;
}

.apt {
  align-items: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}

.wow h2 {
  color: #4268AE;
  font-size: 2rem;
  font-weight: 600;
}

.wow span {
  color: #FBB03B;
}

.sectionf {
  margin: 0 15%;
}

/* Features Section */
.Feat1,
.Feat2 {
  display: flex;
  flex-wrap: nowrap; /* Para magkatabi ang content */
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 4% 0;
}

/* Feat2 reversed direction */
.Feat2 {
  flex-direction: row-reverse;
}

/* Laman ng Feat1 at Feat2 (image + content) */
.featimage,
.featcontent,
.featcontent1 {
  flex: 1;
  max-width: 50%;
  box-sizing: border-box;
}

/* Image styles */
.featimage {
  text-align: center;
  border-radius: 4px;
}

.featimage img {
  width: 100%;
  height: auto;
  max-width: 700px; /* Mas malaki sa desktop */
}

/* ✅ Responsive Design */
@media screen and (max-width: 1024px) {
  .featimage img {
    max-width: 500px; /* Normal size for tablets */
  }
}

@media screen and (max-width: 768px) {
  .featimage img {
    max-width: 100%; /* Full width sa mobile */
  }
}

/* Content styles */
.featcontent,
.featcontent1 {
  padding: 20px;
  text-align: left;
}

.featcontent h4,
.featcontent1 h4 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #424242;
}

.featcontent p,
.featcontent1 p {
  font-size: 1rem;
  font-weight: 400;
  color: #424242;
  
}

/* Signup Button */
.signup {
  background-color: #4268AE;
  border-style: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  /* border: solid 1px red; */
}

/* ✅ Responsive Design */
@media screen and (max-width: 1024px) {
  .sectionf {
    margin: 0 5%;
  }

  .Feat1,
  .Feat2 {
    flex-direction: column; /* ❗ Stacked sa mobile */
    text-align: center;
  }

  .featimage,
  .featcontent,
  .featcontent1 { flex: 1 1 100%; max-width: 100%; } }

  @media screen and (max-width: 768px) { .wow h2 { font-size: 1.5rem; }
  
  .featcontent h4, .featcontent1 h4 { font-size: 1.5rem; }
  
  .featcontent p, .featcontent1 p { font-size: 0.9rem; }
  
  .signup { font-size: 0.9rem; padding: 8px 16px; } }
  
  @media screen and (max-width: 480px) { .wow { padding: 5%; }
  
  .wow h2 { font-size: 1.3rem; }
  
  .featcontent h4, .featcontent1 h4 { font-size: 1.3rem; }
  
  .featcontent p, .featcontent1 p { font-size: 0.85rem; }
  
  .signup { font-size: 0.8rem; padding: 6px 12px; } }




  /* ------------------- slider ------------------------ */

  .featimage {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    cursor: ew-resize;
    text-align: center;
    border-radius: 4px;
    /* border: solid 3px red; */
  }
  
  /* Ensure Before and After images are always visible */
  .before-image,
  .after-image {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* Para hindi mawala ang Before Image */
  .before-image {
    position: relative; /* Static image sa ilalim */
    z-index: 1;
  }
  
  /* Para manatili ang buong After Image at mag-slide nang tama */
  .after-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
  }
  
  .after-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* Vertical Slider Line */
  .slider-line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    cursor: ew-resize;
    z-index: 3;
  }
  
  .slider-handle {
    position: absolute;
    top: 50%;
    left: -10px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    border: 2px solid #000;
    transform: translateY(-50%);
  }
  